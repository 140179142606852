import { EU_COUNTRIES } from '@naturalcycles/shared'
import { SupportedLocale } from '@src/cnst/translations.cnst'
import { languageContext } from '@src/context/language.context'
import { useTranslation } from '@src/hooks/useTranslation'
import { GeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import type { MouseEvent } from 'react'
import { useContext } from 'react'

export enum NavMenuFooterPlacements {
  FooterApp = 'FooterApp',
  FooterScienceResearch = 'FooterScienceResearch',
  FooterCompany = 'FooterCompany',
  FooterSupport = 'FooterSupport',
}

export interface NavMenuItem {
  uid: string
  text: string
  href: string
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
}

export type NavMenuLocaleUrls = Record<SupportedLocale, string>

export type NavMenu = NavMenuItem[]

export function useNavMenuFooter(
  placement: NavMenuFooterPlacements,
  country: GeolocationCountry,
): NavMenu {
  const { locale } = useContext(languageContext)
  const translate = useTranslation()

  function url(urls: NavMenuLocaleUrls): string {
    return urls[locale]
  }

  const menu: NavMenu = []

  switch (placement) {
    case NavMenuFooterPlacements.FooterApp: {
      menu.push({
        uid: 'footer-nav-explanation',
        text: translate('nav-explanation'),
        href: url({
          'en-US': '/how-does-natural-cycles-work',
          'es-US': '/es/how-does-natural-cycles-work',
          'pt-BR': '/pt-br/how-does-natural-cycles-work',
          'sv-SE': '/sv/how-does-natural-cycles-work',
          'en-CA': '/ca/how-does-natural-cycles-work',
        }),
      })
      menu.push({
        uid: 'footer-nav-suitability',
        text: translate('nav-suitability'),
        href: url({
          'en-US': '/is-natural-cycles-right-for-me',
          'es-US': '/es/is-natural-cycles-right-for-me',
          'pt-BR': '/pt-br/is-natural-cycles-right-for-me',
          'sv-SE': '/sv/is-natural-cycles-right-for-me',
          'en-CA': '/ca/is-natural-cycles-right-for-me',
        }),
      })

      menu.push({
        uid: 'footer-nav-plan',
        text: translate('nav-plan'),
        href: url({
          'en-US': '/plan-pregnancy',
          'es-US': '/es/plan-pregnancy',
          'pt-BR': '/pt-br/plan-pregnancy',
          'sv-SE': '/sv/plan-pregnancy',
          'en-CA': '/ca/plan-pregnancy',
        }),
      })

      menu.push({
        uid: 'footer-nav-postpartum',
        text: translate('nav-postpartum'),
        href: url({
          'en-US': '/postpartum',
          'es-US': '/es/postpartum',
          'pt-BR': '/pt-br/postpartum',
          'sv-SE': '/sv/postpartum',
          'en-CA': '/ca/postpartum',
        }),
      })

      if (EU_COUNTRIES.includes(country) || country === GeolocationCountry.US) {
        menu.push({
          uid: 'footer-nav-measuring-devices',
          text: translate('nav-measuring-devices'),
          href: url({
            'en-US': '/devices/eu',
            'es-US': '/es/devices/eu',
            'pt-BR': '/pt-br/devices/eu',
            'sv-SE': '/sv/devices/eu',
            'en-CA': '/ca/devices/eu',
          }),
        })
      }

      menu.push({
        uid: 'footer-nav-testimonials',
        text: translate('nav-testimonials'),
        href: url({
          'en-US': '/reviews',
          'es-US': '/es/reviews',
          'pt-BR': '/pt-br/reviews',
          'sv-SE': '/sv/reviews',
          'en-CA': '/ca/reviews',
        }),
      })
      break
    }

    case NavMenuFooterPlacements.FooterCompany: {
      menu.push({
        uid: 'footer-nav-about',
        text: translate('nav-about'),
        href: url({
          'en-US': '/about',
          'es-US': '/es/about',
          'pt-BR': '/pt-br/about',
          'sv-SE': '/sv/about',
          'en-CA': '/ca/about',
        }),
      })

      menu.push({
        uid: 'footer-nav-careers',
        text: translate('nav-careers'),
        href: 'https://career.naturalcycles.com',
      })

      if (locale === 'en-US' || locale === 'sv-SE') {
        menu.push({
          uid: 'footer-nav-affiliate',
          text: locale === 'sv-SE' ? 'Affiliateprogram' : 'Affiliate Program',
          href: '/c/affiliate',
        })
      }

      menu.push({
        uid: 'footer-nav-promo-code',
        text: 'Natural Cycles Promo',
        href: url({
          'en-US': '/promo-code',
          'es-US': '/promo-code',
          'pt-BR': '/promo-code',
          'sv-SE': '/promo-code',
          'en-CA': '/promo-code',
        }),
      })

      if (locale === 'en-US' || locale === 'en-CA') {
        menu.push({
          uid: 'footer-nav-sheerid',
          text: 'Natural Cycles Special Discounts',
          href: '/healthcare-teachers-military-first-responders-discount',
        })
        menu.push({
          uid: 'nav-news',
          text: 'Press Hub',
          href: '/press-hub',
        })
      }

      menu.push({
        uid: 'footer-nav-advisory-board',
        text: translate('nav-advisory-board'),
        href: url({
          'en-US': '/advisory-board',
          'es-US': '/es/advisory-board',
          'pt-BR': '/pt-br/advisory-board',
          'sv-SE': '/sv/advisory-board',
          'en-CA': '/ca/advisory-board',
        }),
      })

      break
    }

    case NavMenuFooterPlacements.FooterScienceResearch: {
      menu.push({
        uid: 'footer-nav-science',
        text: translate('nav-science'),
        href: url({
          'en-US': '/the-science',
          'es-US': '/es/the-science',
          'pt-BR': '/pt-br/the-science',
          'sv-SE': '/sv/the-science',
          'en-CA': '/ca/the-science',
        }),
      })

      menu.push({
        uid: 'footer-nav-effectiveness',
        text: translate('nav-effectiveness'),
        href: url({
          'en-US': '/how-effective-is-natural-cycles',
          'es-US': '/es/how-effective-is-natural-cycles',
          'pt-BR': '/pt-br/how-effective-is-natural-cycles',
          'sv-SE': '/sv/how-effective-is-natural-cycles',
          'en-CA': '/ca/how-effective-is-natural-cycles',
        }),
      })

      menu.push({
        uid: 'footer-nav-cyclematters',
        text: translate('nav-cyclematters'),
        href: url({
          'en-US': '/cyclematters',
          'pt-BR': '/cyclematters',
          'es-US': '/es/cyclematters',
          'sv-SE': '/sv/cyclematters',
          'en-CA': '/cyclematters',
        }),
      })

      if (locale === 'en-US') {
        menu.push(
          { uid: 'footer-nav-library', text: 'Research Library', href: '/research-library' },
          {
            uid: 'footer-nav-publications',
            text: 'Research & Publications',
            href: '/publications',
          },
        )
      }

      menu.push({
        uid: 'footer-nav-hcp',
        text: translate('nav-hcp'),
        href: url({
          'en-US': '/hcp',
          'es-US': '/hcp',
          'pt-BR': '/hcp',
          'sv-SE': '/sv/hcp',
          'en-CA': '/hcp',
        }),
      })

      if (locale === 'en-US') {
        menu.push({
          uid: 'footer-period-calculator',
          text: 'Period Calculator',
          href: '/cyclematters/period-calculator',
        })
      }

      break
    }

    case NavMenuFooterPlacements.FooterSupport: {
      menu.push({
        uid: 'footer-nav-support',
        text: translate('nav-support'),
        href: url({
          'en-US': 'https://help.naturalcycles.com/hc/en-us',
          'es-US': 'https://help.naturalcycles.com/hc/en-us',
          'pt-BR': 'https://help.naturalcycles.com/hc/pt',
          'sv-SE': 'https://help.naturalcycles.com/hc/sv',
          'en-CA': 'https://help.naturalcycles.com/hc/en-ca',
        }),
      })

      menu.push({
        uid: 'footer-nav-account',
        text: translate('nav-account'),
        href: url({
          'en-US': 'https://signup.naturalcycles.com/en-US/account/login',
          'es-US': 'https://signup.naturalcycles.com/es-US/account/login',
          'pt-BR': 'https://signup.naturalcycles.com/pt-BR/account/login',
          'sv-SE': 'https://signup.naturalcycles.com/sv-SE/account/login',
          'en-CA': 'https://signup.naturalcycles.com/en-CA/account/login',
        }),
      })

      menu.push({
        uid: 'footer-nav-faqs',
        text: translate('nav-faqs'),
        href: url({
          'en-US': '/faqs',
          'es-US': '/es/faqs',
          'pt-BR': '/pt-br/faqs',
          'sv-SE': '/sv/faqs',
          'en-CA': '/ca/faqs',
        }),
      })

      menu.push({
        uid: 'footer-nav-manual',
        text: translate('nav-manual'),
        href: url({
          'en-US':
            'https://help.naturalcycles.com/hc/en-us/articles/360003302953-Instructions-for-use',
          'es-US':
            'https://help.naturalcycles.com/hc/en-us/articles/360003302953-Instructions-for-use',
          'pt-BR':
            'https://help.naturalcycles.com/hc/pt/articles/360003302953-Instructions-for-use',
          'sv-SE': 'https://help.naturalcycles.com/hc/sv/articles/360003302953-Bruksanvisning',
          'en-CA':
            'https://help.naturalcycles.com/hc/en-ca/articles/360003302953-Instructions-for-use',
        }),
      })

      menu.push({
        uid: 'footer-nav-shop',
        text: translate('nav-store'),
        href: '/shop',
      })

      menu.push({
        uid: 'footer-nav-secure',
        text: 'NC° Secure',
        href: url({
          'en-US': '/secure',
          'es-US': '/es/secure',
          'pt-BR': '/pt-br/secure',
          'sv-SE': '/sv/secure',
          'en-CA': '/ca/secure',
        }),
      })

      if (locale === 'en-US') {
        menu.push({
          uid: 'footer-nav-insurance',
          text: 'Insurance',
          href: '/insurance',
        })
      }
      break
    }
  }

  return menu
}
